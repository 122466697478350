.historyList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
}

.historyCard {
    background-color: #2c2c38;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-left: 4px solid #4CAF50;
    color: #fff;
    transition: transform 0.2s, box-shadow 0.3s ease-in-out;
    position: relative;
}

.historyCard:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: #4CAF50;
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.7),
    0 0 20px rgba(76, 175, 80, 0.5);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 8px 0 0 8px;
}

.historyCard:hover {
    transform: scale(1.02);
}


.historyCard p {
    margin: 8px 0;
}

.historyCardTitle {
    font-weight: bold;
    font-size: 18px;
}

.historyCardDivider {
    height: 1px;
    background-color: #555;
    margin: 10px 0;
}

@media (max-width: 768px) {
    .historyList {
        max-height: 60vh;
    }
}
