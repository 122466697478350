
.regionTitle {
    font-weight: bold;
    border-color: #282c34;
    fill: #fff;
    text-shadow: 1px 1px 2px #000;
}

.map {
    width: 70%;
    filter: drop-shadow(0 0 10px rgb(75, 0, 126));
    transition: all 0.3s ease;
}

g{
    cursor: pointer;
    transition: all 0.3s ease;
    transform-origin: center;
}

g:hover {
    transform: scale(1.02);
    z-index: 100000000000000;
    stroke-width: 1.5px;
}

.hint {
    background-color: #2c2c38;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5),
    0 0 15px rgba(75, 0, 126, 0.7),
    0 0 30px rgba(75, 0, 126, 0.5);
    border-left: 4px solid #4CAF50;
    color: #fff;
    transition: transform 0.2s, box-shadow 0.3s ease-in-out;
    font-size: 14px;
    max-width: 200px;
    animation: glowPulse 2s infinite alternate;
    position: relative;
}

.hint:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: #4CAF50;
    box-shadow: 0 0 15px rgba(76, 175, 80, 0.7),
    0 0 30px rgba(76, 175, 80, 0.5);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 8px 0 0 8px;
}

@keyframes glowPulse {
    from {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5),
        0 0 10px rgba(75, 0, 126, 0.5),
        0 0 20px rgba(75, 0, 126, 0.4);
    }
    to {
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6),
        0 0 25px rgba(75, 0, 126, 0.9),
        0 0 45px rgba(75, 0, 126, 0.8);
    }
}


@media (max-width: 768px) {
    .hint {
        font-size: 12px;
        max-width: 150px;
    }
    .map {
        width: 100vw;
        height: auto;
    }
    g{
        user-select: none;
    }
}
