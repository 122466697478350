.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
}

.modalContent {
    background-color: #1e1e2e;
    border-radius: 12px;
    padding: 20px;
    width: 60%;
    max-width: 600px;
    box-shadow: 0 0 20px rgb(75, 0, 126);
    color: #f0f0f5;
    font-family: 'Segoe UI', sans-serif;
    position: relative;
    overflow-y: auto;
}

.closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: transparent;
    border: none;
    color: #f0f0f5;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.closeButton:hover {
    color: #ff4c4c;
}

.modalContent ul {
    list-style-type: none;
    padding: 0;
}

.modalContent li {
    margin-bottom: 15px;
}

.modalContent p {
    margin: 5px 0;
    font-size: 16px;
}

@media (max-width: 768px) {
    .modalContent {
        width: 90%;
    }
}
