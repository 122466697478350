.App {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

.contentWrapper {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
}

.historyButton {
    position: fixed;
    top: 2%;
    left: 1%;
    padding: 12px 24px;
    background: linear-gradient(150deg, #4caf50, #81c784);
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.4);
    transition: background-color 2s, box-shadow 2s;
    z-index: 100;
    font-weight: bold;
    text-shadow: 1px 1px 2px #000;
}


.historyButton:hover {
    background: linear-gradient(90deg, #388e3c, #66bb6a);
    transform: scale(1.05);
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 110%;
    height: 110%;
    background-image: url('/public/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1px);
    z-index: 1;
    overflow: hidden;
}

.titleWrapper {
    position: relative;
    width: 80%;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.title {
    font-size: 34px;
    color: white;
    font-weight: bold;
    text-shadow: 3px 3px 6px #4b007e;
    text-align: center;
    transition: all 0.7s ease;
    z-index: 2;
}


.title:hover {
    text-shadow: 5px 5px 30px rgba(255, 87, 248, 0.8);
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #2b2e38;
}

::-webkit-scrollbar-thumb {
    background-color: #3a3f51;
    border-radius: 10px;
    border: 2px solid #2b2e38;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #4ea54f;
}

::-webkit-scrollbar-corner {
    background-color: #2b2e38;
}

@media (max-width: 768px) {
    .App {
        padding: 10px;
    }
    
    .historyButton {
        top: 90%;
        bottom: 5%;
        left: 5%;
        font-size: 14px;
        padding: 8px 16px;
    }
    
    .contentWrapper {
        flex-direction: column;
        align-items: center;
    }
    
    .title {
        font-size: 28px;
    }
    
    .titleWrapper {
        top: 15px;
        width: 100%;
    }
    
    .background {
        background-position: center;
        background-size: cover;
    }
}
