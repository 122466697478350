.alarmContainer {
    width: 25%;
    max-width: 600px;
    background-color: #1e1e2e;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 0 20px rgb(75, 0, 126);
    color: #f0f0f5;
    font-family: 'Segoe UI', sans-serif;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    margin: 0 auto;
}

.alarmTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}


.scrollableList {
    overflow-y: auto;
    max-height: 75vh;
    padding-right: 12px;
}

.alarmCard {
    background: linear-gradient(145deg, #1e1e2e, #23233a);
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 12px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.alarmCard:hover {
    transform: scale(1.02);
}

.alarmCardTitle {
    font-size: 18px;
    font-weight: 600;
    color: #81c784;
    margin-bottom: 8px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    transition: text-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
}

.alarmCardDivider {
    height: 2px;
    background: linear-gradient(90deg, #81c784, #388e3c);
    border-radius: 1px;
    margin: 10px 0;
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.7), 0 0 20px rgba(76, 175, 80, 0.5);
    transition: box-shadow 0.3s ease-in-out;
}

.alarmCard:hover .alarmCardTitle {
    color: #a5d6a7;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

.alarmCard:hover .alarmCardDivider {
    box-shadow: 0 0 20px rgba(76, 175, 80, 0.9), 0 0 40px rgba(76, 175, 80, 0.7);
}



.loading {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

@media (max-width: 768px) {
    .alarmContainer {
        width: 90%;
        max-height: 100vh;
    }
}

